.app__logo {
    cursor: pointer;
}
  
.app__header {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.1rem;
    height: 5rem;
    min-height: 5rem;
    padding: 1rem 5rem;
    color: #888;
    border-bottom: 3px solid #00a1e0;  
}

.app__header>img {
    margin-right: 1rem;
}

.app__header>nav {
    margin-left: auto;
}