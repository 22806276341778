form {
    text-align: left;
    padding: 3vh 10vw;
    max-width: 70%;
}
.form-group {
    margin-bottom: 1rem;
}
small {
    margin-left: 2vw;
}
.form-group.required .form-label:after {
    content:"*";
    color:red;
}
.formError {
    color:red;
}
@media only screen and (max-width: 1400px) {
    form {
        max-width: 90%;
    }
}
@media only screen and (max-width: 1024px) {
    form {
        max-width: unset;
    }
}
.submissionConfirmation {
    padding: 3vh 10vw;
    font-size: 2em;
}