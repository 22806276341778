.carousel-caption {
    bottom: unset !important;
    top: 30%;
    color: #032e61 !important;
}
.carousel-caption h2 {
    font-size: 3rem;
    margin-bottom: 5%;
}
.carousel-caption p {
    text-align: left;
    font-size: 1.75rem;
}
.carousel {
    padding: 5rem 0;
}
.carousel-item {
    cursor: pointer;
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23032e61' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23032e61' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
@media only screen and (max-width: 800px) {
    .carousel {
        padding: 0 1vw;
        margin-top: 10vh;
        margin-bottom: 5vh;
    }
    .carousel-caption h2 {
        font-size: 1rem;
        margin-bottom: 3%;
    }
    .carousel-caption p {
        font-size: .9rem;
    }
    .carousel-item {
        min-height: 50vh;
    }
    .d-block {
        min-height: 50vh;
    }
}
@media only screen and (max-width: 300px) {
    .carousel-caption p {
        font-size: .75rem;
    }
}
@media only screen and (min-width: 1400px) {
    .container-fluid {
        padding: 0px 15vw !important;
    }
}
@media only screen and (min-width: 1600px) {
    .container-fluid {
        padding: 0px 25vw !important;
    }
}
