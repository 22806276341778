.app__privacy {
    text-align: center;
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .app__privacy__content {
    text-align: left;
    padding: 5vh 10vw;
  }

  .app__privacy__recapture {
    margin-top: 3rem;
    font-size: 0.8rem;
    text-align: left;
  }